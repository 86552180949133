<template>
  <div class="flex flex-col xl:flex-row mt-12">
    <div
      class="
        bg-white
        rounded-cu
        shadow
        w-full
        xl:w-400
        xl:mr-5
        mb-5
        xl:mb-0
        mt-2
      "
    >
      <ul class="card-nav">
        <li
          class="text-lg py-4 px-3 cursor-pointer"
          @click="
            page = 1;
            menu = -1;
          "
          :class="
            page === 1
              ? 'bg-dokBlue-ultra text-white text-lg font-bold py-4 px-5'
              : ''
          "
        >
          <span>Informations confidentielles</span>
        </li>
        <li
          class="text-lg py-4 px-3 cursor-pointer"
          @click="
            page = 2;
            menu = -1;
          "
          :class="
            page === 2
              ? 'bg-dokBlue-ultra text-white text-lg font-bold py-4 px-5'
              : ''
          "
        >
          <span>Profile public</span>
        </li>
        <li
          class="text-lg py-4 px-3 cursor-pointer"
          @click="
            page = 3;
            menu = -1;
          "
          :class="
            page === 3 ? 'bg-nblue text-white text-lg font-bold py-4 px-5' : ''
          "
        >
          <span>Mot de passe</span>
        </li>
      </ul>
    </div>
    <div class="bg-white rounded-cu shadow w-full">
      <div class="w-full" v-if="user._id">
        <div v-if="page === 1">
          <form v-on:submit.prevent="onClick">
            <h1
              class="
                border-0
                font-semibold
                border-b border-solid border-gray-400
                py-3
                px-2
                text-blackdok
                cursor-pointer
              "
              style="font-size: 20px"
            >
              Informations confidentielles
            </h1>
            <div class="grid gap-5 grid-cols-1 xl:grid-cols-2 px-12 py-5">
              <div class="flex flex-col">
                <div class="flex flex-col xl:flex-row xl:items-center mb-3">
                  <label class="mr-5 w-200">Prénom</label>
                  <ValidationProvider
                    name="prenom"
                    rules="required"
                    class="w-full"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      placeholder="Prénom"
                      v-model="user.firstName"
                      class="
                        bg-white
                        border border-solid border-gray-300
                        px-2
                        py-3
                        rounded-cu
                        w-full
                      "
                      :class="errors[0] ? 'border-red-600' : ''"
                    />
                    <p
                      v-if="errors[0]"
                      class="text-red-600 text-sm font-semibold uppercase mt-1"
                    >
                      <i class="fas fa-exclamation-circle"></i> Veuillez
                      remplirce champ
                    </p>
                  </ValidationProvider>
                </div>
                <div class="flex flex-col xl:flex-row xl:items-center mb-3">
                  <label class="mr-5 w-200">CIN</label>
                  <ValidationProvider
                    name="CIN"
                    rules="required"
                    class="w-full"
                    v-slot="{ errors }"
                  >
                    <div class="relative flex flex-row">
                      <input
                        type="text"
                        placeholder="CIN"
                        v-model="user.cin"
                        class="
                          w-full
                          bg-white
                          border border-solid border-gray-300
                          px-2
                          py-3
                          rounded-cu
                          pr-8
                        "
                        :class="errors[0] ? 'border-red-600' : ''"
                      />
                      <span
                        class="
                          absolute
                          right-0
                          h-full
                          flex
                          justify-center
                          items-center
                        "
                        ><i
                          class="far fa-question-circle cursor-pointer px-3"
                          v-tooltip="$getInfoBull('cin')"
                        ></i
                      ></span>
                    </div>
                    <p
                      v-if="errors[0]"
                      class="text-red-600 text-sm font-semibold uppercase mt-1"
                    >
                      <i class="fas fa-exclamation-circle"></i> Veuillez remplir
                      ce champ
                    </p>
                  </ValidationProvider>
                </div>
                <div class="flex flex-col xl:flex-row xl:items-center mb-3">
                  <label class="mr-5 w-200">Mobile Pro</label>
                  <ValidationProvider
                    name="Mobile Professionnel"
                    rules="required"
                    class="w-full"
                    v-slot="{ errors }"
                  >
                    <div class="relative flex flex-row">
                      <input
                        type="text"
                        placeholder="Numéro de mobile Professionnel"
                        v-model="user.mobile"
                        class="
                          w-full
                          bg-white
                          border border-solid border-gray-300
                          px-2
                          py-3
                          rounded-cu
                          pr-8
                        "
                      />
                      <span
                        class="
                          absolute
                          right-0
                          h-full
                          flex
                          justify-center
                          items-center
                          px-3
                        "
                        ><i
                          class="far fa-question-circle cursor-pointer"
                          v-tooltip="$getInfoBull('mobile')"
                        ></i
                      ></span>
                    </div>
                    <p
                      v-if="errors[0]"
                      class="text-red-600 text-sm font-semibold uppercase mt-1"
                    >
                      <i class="fas fa-exclamation-circle"></i> Veuillez remplir
                      ce champ
                    </p>
                  </ValidationProvider>
                </div>
                <div
                  class="flex flex-col xl:flex-row xl:items-center mb-3 hidden"
                >
                  <label for="prenom" class="mr-5 w-200"
                    >CNSS /<br class="xl:inline hidden" />ASSURANCE</label
                  >
                  <input
                    type="text"
                    placeholder="CNSS/ASSURANCE"
                    v-model="user.CNSS"
                    class="
                      bg-white
                      border border-solid border-gray-300
                      px-2
                      py-3
                      rounded-cu
                      w-full
                    "
                  />
                </div>
              </div>
              <div class="flex flex-col">
                <div class="flex flex-col xl:flex-row xl:items-center mb-3">
                  <label class="mr-5 w-200">Nom</label>
                  <ValidationProvider
                    name="Nom de famille"
                    rules="required"
                    class="w-full"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      placeholder="Nom de famille"
                      v-model="user.lastName"
                      class="
                        bg-white
                        border border-solid border-gray-300
                        px-2
                        py-3
                        rounded-cu
                        w-full
                        mr-2
                      "
                      :class="errors[0] ? 'border-red-600' : ''"
                    />
                    <p
                      v-if="errors[0]"
                      class="text-red-600 text-sm font-semibold uppercase mt-1"
                    >
                      <i class="fas fa-exclamation-circle"></i> Veuillez remplir
                      ce champ
                    </p>
                  </ValidationProvider>
                </div>
                <div class="flex flex-col xl:flex-row xl:items-center mb-3">
                  <label class="mr-5 w-200">Date de naissance</label>
                  <birth-day
                    :date-save="user.birthday"
                    :on-set-date="setBirthDay"
                    class="mr-2 cursor-pointer"
                  ></birth-day>
                </div>
                <div
                  class="flex flex-col xl:flex-row xl:items-center mt-2 mb-3"
                >
                  <label class="mr-5 w-200">Email</label>
                  <ValidationProvider
                    name="Email"
                    rules="required|email"
                    class="w-full"
                    v-slot="{ errors }"
                  >
                    <label
                      class="
                        w-full
                        bg-white
                        border border-solid border-gray-300
                        px-2
                        py-3
                        rounded-cu
                        block
                      "
                      >{{ user.email }}</label
                    >
                    <p
                      v-if="errors[0]"
                      class="text-red-600 text-sm font-semibold uppercase mt-1"
                    >
                      <i class="fas fa-exclamation-circle"></i> Veuillez remplir
                      ce champ
                    </p>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div
              v-if="page == 1"
              class="w-full flex flex-row justify-center items-center mt-5 mb-0"
            >
              <button
                type="submit"
                class="bg-dokBlue-ultra py-4 px-5 border-0 text-white rounded-t-cu cursor-pointer py-3 w-full font-bold text-lg"
              >
                Sauvegarder
              </button>
            </div>
          </form>
        </div>
        <div>
          <profile v-if="page === 2"></profile>
          <password v-if="page === 3"></password>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import password from "@/views/pharmacy/settings/password";
import profile from "@/views/pharmacy/settings/profile";
import birthDay from "@/views/global-components/birthday";

export default {
  components: {
    birthDay,
    password,
    profile
  },
  data() {
    return {
      user: {
        professionalInfo: {
          INPE: null
        },
        landingPage: {
          address: {}
        }
      },
      page: 1,
      menu: -1
    };
  },
  async mounted() {
    this.user = await this.$auth.getUserInfo();
  },
  methods: {
    ...mapActions("user", ["UPDATE_PROFILE"]),
    setBirthDay: function(data) {
      this.user.birthday = data;
    },
    onClick: async function() {
      this.$vs.loading({
        background: "#000"
      });

      await this.UPDATE_PROFILE({
        data: this.user,
        onData: this.onData
      });
    },
    onData(data) {
      this.$auth.setUserInfo(data.data);
      this.$vs.loading.close();
      this.$vs.notify({
        time: 4000,
        text: data.ok ? "Mis à jour avec succès" : data.message,
        color: data.ok ? "success" : "danger",
        iconPack: "feather",
        icon: data.ok ? "icon-check" : "icon-x"
      });
    }
  }
};
</script>
